import React from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"

import SliderLeft from "../../assets/images/icons/slider-left.svg"
import SliderRight from "../../assets/images/icons/slider-right.svg"
import "./ProductsSlider.scss"

const ProductsSlider = ({ products }) => {
  const slideAmount = 250

  const [moved, setMoved] = React.useState(0)
  const [isSlideLeftDisabled, setIsSlideLeftDisabled] = React.useState(true)
  const [isSlideRightDisabled, setIsSlideRightDisabled] = React.useState(false)
  const scroller = React.useRef()

  React.useEffect(() => {
    if (moved === 0) {
      setIsSlideLeftDisabled(true)
    } else {
      setIsSlideLeftDisabled(false)
    }

    if (moved * -1 > products.length * slideAmount) {
      setIsSlideRightDisabled(true)
    } else {
      setIsSlideRightDisabled(false)
    }
  }, [moved])

  const slideLeft = () => {
    if (moved === 0) {
      return null
    } else {
      setMoved(moved + slideAmount)
      scroller.current.scrollLeft -= 250
    }
  }
  const slideRight = () => {
    if (moved * -1 > products.length * slideAmount) {
      return null
    } else {
      setMoved(moved - slideAmount)
      scroller.current.scrollLeft += 250
    }
  }

  return (
    <section className="product-slider">
      <div className="product-slider__container">
        <div
          className="product-slider__products"
          ref={scroller}
          style={{
            scrollBehaviour: "smooth",
          }}
        >
          {products.map((product) => (
            <Link
              to={`/shop/${product.handle}/`}
              className="product-slider__products__product-card"
              key={product.id}
            >
              <div className="product-slider__products__product-card__image">
                <div>
                  <img
                    src={product.images[0].originalSrc}
                    alt={product.title}
                  />
                </div>
              </div>
              <div className="product-slider__products__product-card__name">
                {product.title}
              </div>
              <div className="product-slider__products__product-card__product-type">
                {product.productType}
              </div>
              <div className="product-slider__products__product-card__price">
                $ {Number(product.priceRange.minVariantPrice.amount).toFixed(2)}{" "}
                - ${" "}
                {Number(product.priceRange.maxVariantPrice.amount).toFixed(2)}
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className="product-slider__container">
        <div className="product-slider__controls">
          <button
            className={`product-slider__controls__button ${
              isSlideLeftDisabled ? "is-disabled" : ""
            }`}
            onClick={slideLeft}
          >
            <img src={SliderLeft} />
          </button>
          <button
            className={`product-slider__controls__button ${
              isSlideRightDisabled ? "is-disabled" : ""
            }`}
            onClick={slideRight}
          >
            <img src={SliderRight} />
          </button>
        </div>
      </div>
      <div className="product-slider__horizontal-rule" />
    </section>
  )
}

ProductsSlider.propTypes = {
  products: PropTypes.array.isRequired,
}

export default ProductsSlider
