import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import InstagramSection from "../components/AboutContact/InstagramSection"
import BriefIntroSection from "../components/BriefIntro/BriefIntroSection"
import ProductsSlider from "../components/Home/ProductsSlider"
import ShopPrints from "../components/Home/ShopPrints"
import ShopApparel from "../components/Home/ShopApparel"

import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const { products } = data.shopifyCollection

  React.useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      if (e.target.tagName === "IMG") {
        e.preventDefault()
        e.stopPropagation()
      }
    })
  }, [])

  return (
    <>
      <SEO path="/" title="Jessica Coppet" />
      <ProductsSlider products={products} />
      <BriefIntroSection />
      <ShopPrints />
      <ShopApparel />
      <InstagramSection />
    </>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const query = graphql`
  query AllProductsForSlider {
    shopifyCollection(title: { eq: "Home page" }) {
      products {
        id
        shopifyId
        title
        handle
        productType
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        images {
          id
          originalSrc
        }
      }
    }
  }
`
