import React from "react"

import Link from "../../utils/link"

import ShopPrintsImage from "../../assets/images/shop_prints.png"
import arrowFirst from "../../assets/images/icons/arrow_1.svg"
import arrowSecond from "../../assets/images/icons/arrow_2.svg"
import "./ShopPrints.scss"

const ShopPrints = () => {
  return (
    <section className="shop-prints">
      <div className="shop-prints__container">
        <div className="shop-prints__category">
          <div className="shop-prints__category__image">
            <Link to="/shop/all-prints/">
              <img src={ShopPrintsImage} alt="Shop Prints" loading="lazy" />
            </Link>
          </div>
          <div className="shop-prints__category__description">
            <div className="shop-prints__category__description__arrow-mobile">
              <img src={arrowSecond} />
            </div>
            <Link
              to="/shop/all-prints/"
              className="shop-prints__category__description__title"
            >
              Shop prints
            </Link>
            <div className="horizontal-rule" />
            <div className="shop-prints__category__description__text">
              Each of Jessica’s illustrations are digitally hand drawn by
              layering lines. Jessica credits her unique talent for the
              technique known as ‘line art’ to achieving such intricate drawings
              using just a 5 mm ball point pen.
            </div>
            <div className="shop-prints__category__description__arrow">
              <img src={arrowFirst} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ShopPrints
