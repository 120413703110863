import React from "react"

import Link from "../../utils/link"

import ShopApparelImage from "../../assets/images/shop_apparel.png"
import arrowFirst from "../../assets/images/icons/arrow_1.svg"
import arrowSecond from "../../assets/images/icons/arrow_2.svg"
import "./ShopApparel.scss"

const ShopApparel = () => {
  return (
    <section className="shop-apparel">
      <div className="shop-apparel__container">
        <div className="shop-apparel__category">
          <div className="shop-apparel__category__description">
            <div className="shop-apparel__category__description__arrow-mobile">
              <img src={arrowFirst} />
            </div>
            <Link
              to="/shop/apparel/"
              className="shop-apparel__category__description__title"
            >
              Shop Apparel
            </Link>
            <div className="horizontal-rule" />
            <div className="shop-apparel__category__description__text">
              Premium quality graphic tees with exclusive Jessica Coppet prints,
              lovingly made in Australia from 100% ethically sourced, combed
              cotton.
            </div>
            <div className="shop-apparel__category__description__arrow">
              <img src={arrowSecond} />
            </div>
          </div>
          <div className="shop-apparel__category__image">
            <Link to="/shop/apparel/">
              <img src={ShopApparelImage} alt="Shop Apparel" loading="lazy" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ShopApparel
